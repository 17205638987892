import type { useLocation, useNavigation } from '@remix-run/react'

export type TabBaRoutesKey = 'home' | 'help' | 'profile'

export type TabBarPathsMap = Record<TabBaRoutesKey, string | string[]>

const baseLoggedPath = `/lugares`
export const makePlacePath = (placeId: string) => `${baseLoggedPath}/${placeId}`

export const makeTabBarDefaultPaths = (placeId: string) => ({
  home: `${makePlacePath(placeId)}/inicio`,
  help: `${makePlacePath(placeId)}/ajuda`,
  profile: `${makePlacePath(placeId)}/perfil`,
})

interface TransitionParams {
  location: ReturnType<typeof useLocation>
  navigation: ReturnType<typeof useNavigation>
  from: string
  to: string
}

export const isTransitioningBetween = ({
  location,
  navigation,
  from,
  to,
}: TransitionParams) =>
  location.pathname.includes(from) && navigation.location?.pathname.includes(to)
